.impressum-v1 {
	margin-top: var(--page-margin-top);

	.logo {

		height: 120px;

		max-width: 100%;


		@media (max-width:1200px) {
			height: 100px;

		}

		@media (max-width:767px) {
			height: 70px;
			width: auto;

		}
	}
}